export default class BaseBrowserExtension {

  constructor(props) {
    this.wallet = props.wallet;
    this.name = props.name;
    this._enabled = props.enabled || false;
    this.ethereum = props.ethereum;
    this.connector = props.connector;
  }

  get chainId() {
    return this._chainId || '';
  }

  get isEnabled() {
    return this._enabled;
  }

  get currentAccount() {
    return this._currentAccount;
  }

  setEnabled(enabled) {
    this._enabled = enabled;
  }

  onEnabled(callback) {
    this._onEnabled = callback;
    // eslint-disable-next-line no-return-assign
    return () => this._onEnabled = undefined;
  }

  callOnEnabled(chainId) {
    this._onEnabled && this._onEnabled(chainId);
  }

  onNetworkChanged(callback) {
    this._onNetworkChanged = callback;
    // eslint-disable-next-line no-return-assign
    return () => this._onNetworkChanged = undefined;
  }

  onAccountChanged(callback) {
    this._onAccountChanged = callback;
    // eslint-disable-next-line no-return-assign
    return () => this._onAccountChanged = undefined;
  }

  onDisconnect(callback) {
    this._onDisconnect = callback;
    // eslint-disable-next-line no-return-assign
    return () => this._onDisconnect = undefined;
  }

  getNetwork(chainId = this._chainId || '') {
    return {
      chainId,
    }
  }
}
