// import WalletConnectProvider from '@walletconnect/web3-provider';
class WalletConnectProvider {

}

import BaseBrowserExtension from './BaseBrowserExtension'

// import networks from '../networks';

export default class WalletConnect extends BaseBrowserExtension {

  constructor (walletProps) {
    const props = {
      name: 'WalletConnect',
      connector: new WalletConnectProvider(walletProps.providerOptions)
    }
    super(props)
  }

  async enable (networkId = 'main') {
    await this.connector.enable()
    const accounts = this.connector.accounts
    this._currentAccount = { address: accounts[0] }
    this._chainId = `0x${this.connector.chainId.toString(16)}`
    // Subscribe to accounts change
    this.connector.on('accountsChanged', (accounts) => {
      this._currentAccount = { address: accounts[0] }
      this._onAccountChanged && this._onAccountChanged({ address: accounts[0] })
    })
    // Subscribe to chainId change
    this.connector.on('chainChanged', (chainId) => {
      // console.log(chainId);
      this._chainId = chainId
      this._onNetworkChanged && this._onNetworkChanged(this.getNetwork())
    })
    // Subscribe to session disconnection
    this.connector.on('disconnect', async (code, reason) => {
      // console.log(code, reason);
      this._onDisconnect && this._onDisconnect()
    })
    this._enabled = true
    return this._chainId
  }

  async sendTransaction (tx) {
    return this.connector.request({
      method: 'eth_sendTransaction',
      params: [tx]
    })
  }

  async signMessage (message) {
    return await this.connector.request({ method: 'eth_sign', params: [this.currentAccount.address, message] })
  }

  async signTypedData (typedData) {
    throw new Error('signTypedData is not supported for ConnectWallet.')
  }

  async personalSign (message) {
    throw new Error('please use signMessage for ConnectWallet.')
  }

  watchAsset (coinParams) {
    throw new Error('ConnectWallet-watchAsset no implementation.')
  }
}
